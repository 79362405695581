<template>
  <div class="title">
    <div class="title-borde"></div>
    <div class="title-text">
        <p>{{titlename}}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: ['titlename'],
  components: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created () { }
}
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  height: 26px;
  // background: red;
  display: flex;
  &-borde {
    width: 4px;
    height: 26px;
    background: #fff;
  }
  &-text {
    width: 250px;
    height: 26px;
    margin-left: 5px;
    background: linear-gradient(88deg, #374862 0%, rgba(230, 252, 255, 0) 100%);
    font-size: 20px;
    // font-family: UnidreamLED;
    font-weight: bold;
    line-height: 26px;
    color: #e6fcff;
    p{
    margin-left: 10px;
    }
  }
}
</style>
